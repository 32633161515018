import { defineMessages } from 'react-intl';

export default defineMessages({
    LegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-start.Adding/remove Legal Expenses cover',
        defaultMessage: 'Adding/remove Personal Legal Expenses'
    },
    OtherChanges: {
        id: 'endorsement.ngh.views.policychange-start.For any other changes',
        defaultMessage: 'For any other changes, or if you would like to make changes over the phone, please call us on 0800 208 8838. We’re available Monday - Saturday, 9am - 5pm.  You can also '
    },
    ArrangeACallBack: {
        id: 'endorsement.ngh.views.policychange-start.Arrange a call back',
        defaultMessage: 'arrange a call back.'
    },
    HomeEmergencyCover: {
        id: 'endorsement.ngh.views.policychange-start.Adding/remove Home Emergency cover',
        defaultMessage: 'Adding/remove Home Emergency'
    },
    AdditionalCoverages: {
        id: 'endorsement.ngh.views.policychange-start.Additional coverages',
        defaultMessage: 'Additional Insurances'
    },
    ContentsAwayFromHomeCover: {
        id: 'endorsement.ngh.views.policychange-start.Contents Away from the Home cover',
        defaultMessage: 'Adding/remove/adjust Contents Away from the Home cover'
    },
    SpecifiedValuables: {
        id: 'endorsement.ngh.views.policychange-start.Adding/remove specified valuables',
        defaultMessage: 'Adding/remove specified valuables, such as jewellery and coins'
    },
    AccidentalDamageCover: {
        id: 'endorsement.ngh.views.policychange-start.Adding/remove accidental damage cover',
        defaultMessage: 'Adding/remove Accidental Damage'
    },
    ContentsCover: {
        id: 'endorsement.ngh.views.policychange-start.Contents cover',
        defaultMessage: 'Contents cover'
    },
    ChangesCanBeMade: {
        id: 'endorsement.ngh.views.policychange-start.The following changes can be made online:',
        defaultMessage: 'The following changes can be made online:'
    },
    AuthorizedAccountMessage: {
        id: 'endorsement.ngh.views.policychange-start.In order to make a change to your policy',
        defaultMessage: 'In order to make a change to your policy, you must be the policy holder or authorised on the account to make changes. For more information on when your need to notify NFUM of a change and what you can change, please see our Policy Changes FAQs or get in touch.'
    },
    BeforeYourStart: {
        id: 'endorsement.ngh.views.policychange-start.beforeYourStart',
        defaultMessage: 'Before you start'
    },
    HighValueBicyclesCover: {
        id: 'endorsement.ngh.views.policychange-start.High Value Bicycles cover',
        defaultMessage: 'Add/remove/edit high valued bicycles'
    },
});
