import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import metadata from './CallOrContactInfo.metadata.json5';
import styles from './CallOrContactInfo.module.scss';
import messages from './CallOrContactInfo.messages';

function CallOrContactInfo(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const {
        header,
        description,
        description1,
        producerDesc,
        producerTele,
        isBespoke,
        showAgencyDetails,
        showCallBack,
        contentsReferral,
        phoneNumber,
        showCallTimings
    } = props;

    const showAgengyDetails = () => {
        if (showAgencyDetails) {
            if (!producerDesc || !producerTele) {
                return false;
            }
            return true;
        }
        return false;
    };
    const showFindAgengySection = () => {
        if (showAgencyDetails) {
            if (!producerDesc || !producerTele) {
                return true;
            }
            return false;
        }
        if (isBespoke) {
            return true;
        }
        return false;
    };

    const onRequestCallback = useCallback(() => {
        const url = ' https://www.nfumutual.co.uk/contact-us/home-ce-call-back';
        pushLinkClickInfo(translator(messages.requestForCallbackText), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.contactYourAgency), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        referralMessageTitle: {
            visible: header != null,
            content: translator(header)
        },
        referralContentMessage: {
            visible: description != null,
            content: translator(description)
        },
        referralContentMessageExtra: {
            visible: description1 != null,
            content: description1
        },
        callTimingsContainer: {
            visible: (!showAgengyDetails() && !isBespoke) || showCallTimings
        },
        findYourAgencyContainer: {
            visible: showFindAgengySection() && isBespoke && !showCallTimings
        },
        callBackContainer: {
            visible: showFindAgengySection() && !isBespoke && showCallBack === true
        },
        contactYourAgencyContainer: {
            visible: showAgengyDetails() && !showCallTimings
        },
        alteranteContactOptionContainer: {
            visible: (!showAgencyDetails && !isBespoke)
            || contentsReferral
        },
        contactYourAgencyText: {
            content: producerDesc ? `${producerDesc}` : ''
        },
        callAgencyPhone: {
            content: `${translator(messages.call)} ${producerTele || phoneNumber || '0800 208 8838'}`
        },
        findYourAgencyButton: {
            onClick: onFindAgencyClicked
        },
        moreInfoButton: {
            onClick: onFindAgencyClicked
        },
        requestForCallbackText: {
            onClick: onRequestCallback
        },
        callBackButton: {
            onClick: onRequestCallback
        },
        callTimingsTitle: {
            content: `${translator(messages.call)} ${phoneNumber || '0800 208 8838'}`
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default CallOrContactInfo;
