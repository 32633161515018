import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { Button } from '@jutro/components';
import styles from './DocumentsLinksComponent.module.scss';

function DocumentsLinksComponent(props) {
    const { documents, pushFileDownloadInfo, isCmsDocument = false } = props;
    const { PolicyService } = useDependencies('PolicyService');
    const { authHeader } = useAuthentication();

    const getNameWithFormat = useCallback((doc) => {
        if (isCmsDocument) {
            return doc.displayName;
        }
        let docFormat = null;
        const mimeTypeParts = doc?.mimeType?.split('/');
        if (mimeTypeParts) {
            docFormat = _.last(mimeTypeParts)?.toUpperCase();
        }
        return docFormat ? `${doc.name} (${docFormat})` : doc.name;
    }, [isCmsDocument]);

    const onDocumentClicked = useCallback(async (doc) => {
        let docUrl = '';
        if (isCmsDocument) {
            docUrl = doc.document.url;
        } else {
            docUrl = await PolicyService
                .getDocumentUrl([doc.publicID, doc.sessionID], authHeader);
        }
        pushFileDownloadInfo(getNameWithFormat(doc), docUrl);
        window.open(docUrl, '_blank');
    }, [isCmsDocument, PolicyService, pushFileDownloadInfo, getNameWithFormat, authHeader]);

    return (
        <div>
            { documents?.map((doc) => {
                return (
                    <div
                        className={styles.link}
                        key={doc.publicID}
                    >
                        <div className={styles.nfumDocumentLinkContainer}>
                            <Button
                                id="doc-link"
                                type="text"
                                icon="cust-download"
                                onClick={() => onDocumentClicked(doc)}
                                iconPosition="left"
                            >
                                { getNameWithFormat(doc) }
                            </Button>
                        </div>
                    </div>
                );
            }) }
        </div>
    );
}

export default DocumentsLinksComponent;
